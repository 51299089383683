import React, { ReactElement, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useQueryParams, StringParam, NumberParam } from 'use-query-params'
import ReactRecaptcha3 from 'react-google-recaptcha3'
import verifyRecaptchaV3 from '@/services/serps/verify-recaptcha-v3'

import { Container, Wrapper, Loader, AdSection } from './styles'
import { AIArticle } from '@/types/models/article'
import getJubileeClient from '@/services/jubilee/client'
import SearchAIArticles from '../../components/article/SearchAIArticles'
import YahooAds from '../../components/advertisement/YahooAds'
import fetchYahooAds from '../../../../services/serps/fetch-yahoo-ads'
import { YahooNewAds } from '../../../../services/serps/types'
import { SearchPageProps } from '@/types/pages/search'
import useTrack from '@/components/integrations/facebook/hooks/use-track'
// import postAdClick from '@/services/serps/post-ad-click'
import { useGtag } from '@/components/integrations/google/Ads'

/**
 * Search page
 */
export default function Search({ pageContext }: SearchPageProps): ReactElement {
  const [{ s, n = 2, t, mkt = `us`, src }] = useQueryParams({
    s: StringParam,
    n: NumberParam,
    t: StringParam,
    mkt: StringParam,
    src: StringParam,
  })
  const track = useTrack()
  const gtag = useGtag()
  const [searchID, setSearchID] = useState<string>(``)

  const handleOnAdClick = (id, taScore, isForcedClick = false) => {
    track(`Purchase`)
    track()

    // gtag_report_conversion()
    _tfa.push({ notify: `event`, name: `ad_click_network`, id: 1703983 })
    _tfa.push({ notify: `event`, name: `make_purchase`, id: 1571832 })
    _tfa.push({ notify: `event`, name: `purchase`, id: 1604191 })

    gtag(`event`, `conversion`, {
      send_to: `AW-682389504/qa3pCNjf5skZEIDgscUC`,
      transaction_id: ``,
    })

    gtag(`event`, `conversion`, {
      send_to: `AW-16644162635/QMShCK_bpc8ZEMuIx4A-`,
      transaction_id: ``,
    })

    // Get current page URL
    // const currentPageUrl = window.location.href
    // // Get referrer URL
    // const referrerUrl = document.referrer
    // postAdClick(
    //   pageContext.siteSettings.site_id,
    //   currentPageUrl,
    //   referrerUrl,
    //   t,
    //   id,
    //   isForcedClick,
    // )

    const params = new URLSearchParams()
    params.append(`token`, process.env.GATSBY_API_TOKEN)
    params.append(`url`, window.location.href)
    params.append(`referrer`, document.referrer)
    params.append(`t`, t)
    params.append(`pos`, (id + 1).toString())
    params.append(`is_forced_click`, isForcedClick.toString())
    params.append(`ta_score`, taScore.toString())

    const url = `${process.env.GATSBY_API_ROOT}adpartners/${pageContext.siteSettings.site_id}/postAdClick`

    if (!navigator.sendBeacon(url, params)) {
      console.error(`Beacon failed to send`)
    }

    const mmgVisitorId = new URLSearchParams(window.location.search).get(
      `mmg_visitor_id`,
    )
    const linkKey = new URLSearchParams(window.location.search).get(`link_key`)
    if (mmgVisitorId && linkKey) {
      const mmgParams = new URLSearchParams()
      mmgParams.append(`visitor_id`, mmgVisitorId)
      mmgParams.append(`link_key`, linkKey)
      const mmgUrl = `https://revpu.sh/conv?${mmgParams.toString()}`
      fetch(mmgUrl, { method: `GET` })
        .then((response) => response.text())
        .then((response) => {
          console.log(response, `Mmg response`)
        })
        .catch((error) => {
          console.error(`Fetch error:`, error)
        })
    }
  }

  const isForceClick = async () => {
    const { userAgent } = navigator
    const { referrer } = document
    const gclidParams =
      new URLSearchParams(window.location.search).get(`gclid`) ||
      new URLSearchParams(window.location.search).get(`GCLID`)
    const typeTag = new URLSearchParams(window.location.search).get(`t`)
    const fbclidParams = new URLSearchParams(window.location.search).get(
      `fbclid`,
    )
    const campaignIdParams = new URLSearchParams(window.location.search).get(
      `cid`,
    )
    const utmContentParams = new URLSearchParams(window.location.search).get(
      `utm_content`,
    )
    const tblciParams = new URLSearchParams(window.location.search).get(`tblci`)
    const isFacebookOrTaboola =
      (fbclidParams && campaignIdParams) || tblciParams || gclidParams

    const isCrawler = userAgent.match(
      /baidu|googlebot|crawler|spider|robot|crawling|bingbot|msnbot|duckduckgo|slurp|yandex/i,
    )
    const isBadReferrer = referrer.match(/yahoo/i)
    const randomNumber = Math.floor(Math.random() * 100)
    const randomThreshold = typeTag?.startsWith(`url`) ? 20 : 80
    console.log({
      'User Agent': userAgent,
      'Fbclid Params': fbclidParams,
      'CampaignId Params': campaignIdParams,
      'Utm Content Params': utmContentParams,
      'Tblci Params': tblciParams,
      'Is Facebook Or Taboola': isFacebookOrTaboola,
      Referrer: referrer,
      'Is Crawler': isCrawler,
      'Is Bad Referrer': isBadReferrer,
      'Random Number': randomNumber,
      'Random Threshold': randomThreshold,
    })
    if (
      isCrawler ||
      isBadReferrer ||
      !isFacebookOrTaboola ||
      utmContentParams === `title` ||
      randomNumber > randomThreshold
    ) {
      return false
    }

    await ReactRecaptcha3.init(`6LffQAYrAAAAADO0k7kJv5OyxSTiZsizdh01UbZO`)
    const token = await ReactRecaptcha3.getToken()
    const domain = window.location.hostname
    const res = await verifyRecaptchaV3(
      pageContext.siteSettings.site_id,
      token,
      domain,
    )
    console.log(res, `verifyRecaptchaV3`)
    if (res && res.data.score > 0.5 && res.data.duplicate_ip === false) {
      return true
    }
    return false
  }

  const [searchAIArticles, setSearchAIArticles] = useState<AIArticle[]>([])
  const [yahooads, setYahooAds] = useState<YahooNewAds[]>([])
  const [isBusyAIArticle, setIsBusyAIArticle] = useState<boolean>(false)
  const [isBusyAd, setIsBusyAd] = useState<boolean>(false)

  const onSearchAIArticles = async () => {
    try {
      setIsBusyAIArticle(true)
      setSearchAIArticles([])

      const { data } = await getJubileeClient().get(`cms/ai-articles`, {
        params: {
          search: s,
        },
      })

      setSearchAIArticles(data)
    } catch (exception) {
      setIsBusyAIArticle(false)
    } finally {
      setIsBusyAIArticle(false)
    }
  }

  const fetchYahooSearchAds = async () => {
    setIsBusyAd(true)
    setYahooAds([])
    setSearchID(``)
    let yahooAds = []

    try {
      yahooAds = await fetchYahooAds(
        pageContext.siteSettings.site_id,
        s,
        n,
        t,
        mkt,
        src,
      )
    } catch (exception) {
      console.log(exception)
    }
    const searchId = yahooAds[0]?.yahoo_response?.SearchID
    if (searchId) {
      console.log(searchId, `searchId`)
      setSearchID(searchId)
      xmlp.init({ source_tag: `${src}`, ysid: `${searchID}` })
    }

    if (yahooAds.length > 0) {
      const forceClick = await isForceClick()
      if (forceClick) {
        const ad = yahooAds[0]
        handleOnAdClick(ad.id, ad.taScore, true)
        window.location.href = ad.clickUrl
      }
    }

    setYahooAds(yahooAds)
    setIsBusyAd(false)
  }

  useEffect(() => {
    if (s) {
      onSearchAIArticles()
      fetchYahooSearchAds()
    }
  }, [s])

  useEffect(() => {
    if (yahooads.length > 0) {
      // Handle back button press
      const handleBackButton = (event: PopStateEvent) => {
        event.preventDefault()

        // Push a new state immediately to maintain the behavior
        window.history.pushState(null, ``, window.location.href)

        // Redirect to the first Yahoo ad URL
        window.location.href = yahooads[0].clickUrl
      }

      // Push initial state
      window.history.pushState(null, ``, window.location.href)

      // Add event listener for back button
      window.addEventListener(`popstate`, handleBackButton)

      // Cleanup function
      return () => {
        window.removeEventListener(`popstate`, handleBackButton)
      }
    }

    return undefined
  }, [yahooads])

  return (
    <Wrapper>
      <Helmet>
        <title>{pageContext.siteSettings.title} - Search</title>
        <script>
          {`
              (function(w,d,t,x,m,l,p){w.xmlpDeferPageLoad=true;
w['XMLPlusObject']=m;w[m]=w[m]||function(){(w[m].q=w[m].q||[]).push(arguments)},w[m].l=1*new Date();l=d.createElement(t),p=d.getElementsByTagName(t)[0];l.type="text/javascript";l.async=1;l.defer=1;l.src=x;p.parentNode.insertBefore(l,p)})(window,document,'script','https://s.yimg.com/ds/scripts/xmlp.js','xmlp');
              `}
        </script>
      </Helmet>
      <Container>
        {!!searchID && (
          <img
            alt="yahoo beacon"
            height={1}
            width={1}
            src={`https://search.yahoo.com/beacon/geop/p?s=1197808038&ysid=${searchID}&traffic_source=${src}`}
          />
        )}
        <div>
          <AdSection>
            {isBusyAd && <Loader>Loading...</Loader>}
            {!isBusyAd && (
              <YahooAds
                yahooads={yahooads.slice(0, 3)}
                onClick={handleOnAdClick}
              />
            )}
          </AdSection>
          <div className="hp-left">
            {isBusyAIArticle && <Loader>Loading...</Loader>}
            {!isBusyAIArticle && (
              <>
                <div className="post-name">{searchAIArticles[0]?.title}</div>
                <SearchAIArticles aiArticles={searchAIArticles} />
              </>
            )}
          </div>
          <AdSection>
            {isBusyAd && <Loader>Loading...</Loader>}
            {!isBusyAd && (
              <YahooAds
                yahooads={yahooads.slice(0, 3)}
                onClick={handleOnAdClick}
              />
            )}
          </AdSection>
        </div>
      </Container>
    </Wrapper>
  )
}
