import React, { ReactElement } from 'react'
import { Helmet } from 'react-helmet'

/* eslint-disable no-useless-escape */
export default function Pixel(): ReactElement {
  const url = typeof window !== `undefined` ? window.location.href : ``
  if (!url.includes(`test=true`)) {
    return null
  }
  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`(function(){
   try {
      if (/\bgtm-msr\.appspot\b/i.exec(document.referrer)) return;
      if (/\bfacebookexternalhit|AdsBot-Google|DMBrowser|LinkedInBot|Semrush|facebookcatalog|AdsBot|Googlebot|GoogleOther|Microsoft-BotFramework|moatbot|pingbot|Mediapartners-Google|GoogleAdSenseInfeed|SnapchatAds\b/i.exec(navigator.userAgent)) return;
   } catch(e){}
   var anura = document.createElement('script');
   if ('object' === typeof anura) {
      var urlValues = {};
      var urlParams = window.location.search.slice(1).split('&');
      for (var x in urlParams) urlValues[urlParams[x].split('=')[0]] = urlParams[x].split('=').slice(1).join('');
      var additionalValues = {
         '1': urlValues['utm_content'] || 'none'
      };
      try {
         var additionalData = JSON.stringify(additionalValues);
      } catch(e) {
         try {
            var additionalParams = [];
            for (var x in additionalValues) { if (additionalValues[x]) additionalParams.push('"' + x + '": "' + additionalValues[x] + '"'); }
            var additionalData = "{" + additionalParams.join(', ') + "}";
         } catch(e) {}
      }
      var request = {
         instance: '4084011729',
         source: urlValues['src'] || 'none',
         campaign: urlValues['t'] || 'none',
         additional: additionalData,
         callback: 'anuraResponseCallback'
      };
      var params = [Math.floor(1E12*Math.random()+1)];
      for (var x in request) params.push(x+'='+encodeURIComponent(request[x]));
      anura.type = 'text/javascript';
      anura.async = true
      anura.src = 'https://script.anura.io/request.js?' + params.join('&');
      var script = document.getElementsByTagName('script')[0];
      script.parentNode.insertBefore(anura, script);
   }
})();

function anuraResponseCallback() {
   if (!!Anura) {
      var anuraResultCallback = function() {
         const anuraRes = Anura.getAnura()
         console.log(anuraRes.getObject())
      }
      Anura.getAnura().hasResult() ? anuraResultCallback() : Anura.getAnura().queryResult(anuraResultCallback);
   }
}

`}
        </script>
      </Helmet>
    </>
  )
}
